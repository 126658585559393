/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use strict";
"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TopLoginLogout from "./TopLoginLogout";
import TaskDropdown from "./TaskDropdown";
import { Button, Link, styled, Tooltip } from "@mui/material";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const LEADERBOARD = process.env.NEXT_PUBLIC_LEADERBOARD_URL!;

export default function Navigation() {
  return (
    <Box sx={{ flexGrow: 1, mb: 2 }}>
      <AppBar position="fixed">
        <Toolbar disableGutters>
          <Tooltip title="Return Home">
            <Link href="/">
              <Box
                component="img"
                sx={{
                  display: { xs: "none", md: "flex", width: 48, height: 48 },
                  mr: 3,
                  ml: 2,
                }}
                alt="Super Cloud Logo"
                src="/logo.png"
              />
            </Link>
          </Tooltip>
          <Box sx={{ flexGrow: 1, maxWidth: { xs: "100%", sm: 300 } }}>
            <TaskDropdown />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            <Tooltip title="Open the leaderboard in a new tab">
              <Button
                href={LEADERBOARD}
                target="_blank"
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Leaderboard
              </Button>
            </Tooltip>
          </Box>
          <Box sx={{ mr: 2 }}>
            <TopLoginLogout />
          </Box>
        </Toolbar>
      </AppBar>
      <Offset />
    </Box>
  );
}
