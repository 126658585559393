import { TaskConfig, TasksSchema } from "@hacksday/libscore";
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";

/**
 * Set of functions to get the score from the client side
 */
export const taskConverter: FirestoreDataConverter<TasksSchema> = {
  toFirestore(tasks: TasksSchema): DocumentData {
    return {
      event: tasks.event,
      tasks: Object.fromEntries(tasks.tasks),
    };
  },

  fromFirestore(docSnap: QueryDocumentSnapshot): TasksSchema {
    const data = docSnap.data();
    // Always sort by keys
    return {
      event: data.event,
      tasks: new Map(Object.entries(data.tasks).sort()) as Map<
        string,
        TaskConfig
      >,
    };
  },
};
